import React from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

export default function RequestDemo() {
  return (
    <div className='bg-gray-100'>
      <div className='px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8'>
        <div className='relative bg-white shadow-xl'>
          <h2 className='sr-only'>Contact us</h2>
          <a
            href='https://hybridcore.eu/'
            className='inline-flex items-center justify-center px-6 py-3 mb-4 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto'
          >
            Back
          </a>
          <div className='grid grid-cols-1 lg:grid-cols-3'>
            {/* Contact information */}
            <div className='relative px-6 py-10 overflow-hidden bg-indigo-700 sm:px-10 xl:p-12'>
              <div className='absolute inset-0 pointer-events-none sm:hidden' aria-hidden='true'>
                <svg
                  className='absolute inset-0 w-full h-full'
                  width={343}
                  height={388}
                  viewBox='0 0 343 388'
                  fill='none'
                  preserveAspectRatio='xMidYMid slice'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z'
                    fill='url(#linear1)'
                    fillOpacity='.1'
                  />
                  <defs>
                    <linearGradient
                      id='linear1'
                      x1='254.553'
                      y1='107.554'
                      x2='961.66'
                      y2='814.66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#fff' />
                      <stop offset={1} stopColor='#fff' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className='absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none sm:block lg:hidden'
                aria-hidden='true'
              >
                <svg
                  className='absolute inset-0 w-full h-full'
                  width={359}
                  height={339}
                  viewBox='0 0 359 339'
                  fill='none'
                  preserveAspectRatio='xMidYMid slice'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z'
                    fill='url(#linear2)'
                    fillOpacity='.1'
                  />
                  <defs>
                    <linearGradient
                      id='linear2'
                      x1='192.553'
                      y1='28.553'
                      x2='899.66'
                      y2='735.66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#fff' />
                      <stop offset={1} stopColor='#fff' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className='absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none lg:block'
                aria-hidden='true'
              >
                <svg
                  className='absolute inset-0 w-full h-full'
                  width={160}
                  height={678}
                  viewBox='0 0 160 678'
                  fill='none'
                  preserveAspectRatio='xMidYMid slice'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z'
                    fill='url(#linear3)'
                    fillOpacity='.1'
                  />
                  <defs>
                    <linearGradient
                      id='linear3'
                      x1='192.553'
                      y1='325.553'
                      x2='899.66'
                      y2='1032.66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#fff' />
                      <stop offset={1} stopColor='#fff' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <h3 className='text-lg font-medium text-white'>Contact information</h3>
              <p className='max-w-3xl mt-6 text-base text-indigo-50'>
                Da Vincilaan 1, 1930 Zaventem Belgium
              </p>
              <p className='max-w-3xl mt-6 text-base text-indigo-50'>BTW: BE0752.691.888</p>
              <dl className='mt-8 space-y-6'>
                <dt>
                  <span className='sr-only'>Phone number</span>
                </dt>
                <dd className='flex text-base text-indigo-50'>
                  <PhoneIcon className='flex-shrink-0 w-6 h-6 text-indigo-200' aria-hidden='true' />
                  <span className='ml-3'>+32 (0) 2 801 13 57</span>
                </dd>
                <dt>
                  <span className='sr-only'>Email</span>
                </dt>
                <dd className='flex text-base text-indigo-50'>
                  <MailIcon className='flex-shrink-0 w-6 h-6 text-indigo-200' aria-hidden='true' />
                  <span className='ml-3'>info@hybridcore.eu</span>
                </dd>
              </dl>
            </div>

            {/* Contact form */}
            <div className='px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12'>
              <div className='sm:col-span-2 sm:flex sm:justify-end'></div>
              <h3 className='text-lg font-medium text-gray-900'>Send us a message</h3>
              <form
                action='https://usebasin.com/f/cebae069e378'
                method='POST'
                id='my-contact-form'
                className='grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
              >
                <div>
                  <label htmlFor='first_name' className='block text-sm font-medium text-gray-900'>
                    First name
                  </label>
                  <div className='mt-1'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      autoComplete='given-name'
                      required
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor='last_name' className='block text-sm font-medium text-gray-900'>
                    Last name
                  </label>
                  <div className='mt-1'>
                    <input
                      type='text'
                      name='last_name'
                      id='last_name'
                      autoComplete='family-name'
                      required
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-900'>
                    Email
                  </label>
                  <div className='mt-1'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      autoComplete='email'
                      required
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
                <div>
                  <div className='flex justify-between'>
                    <label htmlFor='phone' className='block text-sm font-medium text-gray-900'>
                      Phone
                    </label>
                    <span id='phone-optional' className='text-sm text-gray-500'>
                      Optional
                    </span>
                  </div>
                  <div className='mt-1'>
                    <input
                      type='text'
                      name='phone'
                      id='phone'
                      autoComplete='tel'
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                      aria-describedby='phone-optional'
                    />
                  </div>
                </div>
                <div className='sm:col-span-2'>
                  <label htmlFor='subject' className='block text-sm font-medium text-gray-900'>
                    Company/Organization
                  </label>
                  <div className='mt-1'>
                    <input
                      type='text'
                      name='subject'
                      id='subject'
                      required
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
                <div className='sm:col-span-2'>
                  <div className='flex justify-between'>
                    <label htmlFor='message' className='block text-sm font-medium text-gray-900'>
                      Message or aim of demo request
                    </label>
                    <span id='message-max' className='text-sm text-gray-500'>
                      Max. 500 characters
                    </span>
                  </div>
                  <div className='mt-1'>
                    <textarea
                      id='message'
                      name='message'
                      rows={4}
                      className='block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                      aria-describedby='message-max'
                      defaultValue={''}
                      required
                    />
                  </div>
                </div>
                <div
                  class='g-recaptcha'
                  data-sitekey='6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy'
                ></div>
                <div className='sm:col-span-2 sm:flex sm:justify-end'>
                  <button
                    type='submit'
                    className='inline-flex items-center justify-center w-full px-6 py-3 mt-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto'
                  >
                    Submit
                  </button>
                </div>
                <div id='form-message'></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
