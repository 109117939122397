import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';

function Ai4kidsCarousel() {
  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);
  const [items] = useState([
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/1.jpg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/2.jpg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/3.jpg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/4.jpg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/5.jpg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/6.jpeg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/7.jpeg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/8.jpeg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/9.jpeg')}
          alt='Tabs 01'
        />
      ),
    },
    {
      image: (
        <img
          className='h-48 md:h-72  mx-auto'
          src={require('../images/AI4Kids/10.jpeg')}
          alt='Tabs 01'
        />
      ),
    },
  ]);

  const testimonials = useRef(null);

  const stopAutorotate = () => {
    setAutorotate(null);
  };

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : (active) => active + 1);
    }, autorotateTiming);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, autorotate]);

  return (
    <div
      className='max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6  mb-0 md:order-1 aos-init aos-animate'
      data-aos='fade-right'
    >
      <div className='md:py-20 border-t border-gray-800'>
        {/* Carousel area*/}
        <div className='max-w-2xl mx-auto pb-12'>
          {/* Carousel */}
          <div className='mt-6 '>
            {/* Testimonials */}
            <div className='relative flex justify-center mb-4' ref={testimonials}>
              {items.map((item, index) => (
                <Transition
                  key={index}
                  show={active === index}
                  appear={true}
                  className='text-center'
                  enter='transition ease-in-out duration-500 transform order-first'
                  enterStart='opacity-0 scale-98'
                  enterEnd='opacity-100 scale-100'
                  leave='transition ease-out duration-300 transform absolute'
                  leaveStart='opacity-100 scale-100'
                  leaveEnd='opacity-0 scale-98'
                >
                  {item.image}
                </Transition>
              ))}
            </div>

            {/* Bullets */}
            <div className='flex items-center justify-center '>
              {items.map((item, index) => (
                <button
                  className='p-1 group'
                  key={index}
                  onClick={() => {
                    setActive(index);
                    stopAutorotate();
                  }}
                >
                  <span
                    className={`block w-2 h-2 rounded-full group-hover:bg-gray-400 transition duration-150 ease-in-out ${
                      active === index ? 'bg-gray-200' : 'bg-gray-500'
                    }`}
                  ></span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ai4kidsCarousel;
