import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const features = [
  {
    name: 'Novel Privacy Protection',
    description: [`Take Charge of Your Full Privacy (Enhanced GDPR Compliance & Encryption)`],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/Augmented Privacy Protection.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Deep Actionable Insights',
    description: [
      `Monitor and Predict Activities,Capacities or Next Steps of Your Potential Customers,Partners,Competitors or Threat Agents`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/Deep Actionable Insights.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Early Warning',
    description: [
      `Know and Monitor Your Anomalies & Risks & Threats in Real Time/Near the Real Time`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/Early Warning.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Better Source Management',
    description: [
      `Optimize your use of assets, materials, human capital, and energy, ultimately reduce costs and improve your operational and environmental impacts via dynamic simulation`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/agile.png')}
        width='70'
        height='70'
        alt='Agile'
      />
    ),
  },
  {
    name: 'Advanced Smart Operations',
    description: [
      `Extract Relations in Your Ecosystem,Receive Automated Recommendations and Get Prediction & Simulation Results with High-Tech Accuracy`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/Advanced Smart Operations.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Strategic Decision Superiority',
    description: [
      `Simulate Multiple Futures for a Strategic Decision Such as Entering New Markets, Targeted Investment,Acquisition, or Using a New Technology`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/icons/Fast&Ethical&Better Strategic Decisions.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
];

export default function BenefitsAndTechnology() {
  return (
    <div className='relative py-10 border-t border-gray-800 sm:py-12 lg:py-16 '>
      <div className='max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
        <p className='mt-2 h1 text-gradient bg-gradient-to-r from-green-400 to-blue-500 sm:text-4xl'>
          BENEFITS & TECHNOLOGY INTRODUCTION
        </p>
        <p class='text-xl text-gray-400 italic'>
          "Protect and Shape Your Future in Partnership with Hybrid Core"
        </p>
        <div className='mt-24'>
          <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
            {features.map((feature) => (
              <div key={feature.name} className='pt-6'>
                <div className='flow-root px-6 pb-8'>
                  <div className='-mt-6'>
                    <div>
                      <span className='inline-flex items-center justify-center rounded-md '>
                        {feature.icon}
                      </span>
                    </div>
                    <h3 className='mt-8 text-lg font-medium text-white'>{feature.name}</h3>
                    <ul className='mt-5'>
                      <li className='text-base text-gray-500 text-left'>
                        {feature.description[0]}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
