import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function HeroFeatures() {
  return (
    <section className='relative pt-16'>
      <div className='relative px-4 mx-auto max-w-8xl sm:px-6 space-y-16'>
        <div className='w-full flex justify-center items-center'>
          <button
            onClick={() => {
              const anchor = document.querySelector('#explore');
              anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
            className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
          >
            <span className='text-sm'>Get Started</span>
          </button>
        </div>
        <div className='relative pb-12 md:pb-20'>
          <div className='max-w-6xl pb-12 mx-auto text-center md:pb-16'>
            <h1
              className='mb-4 text-3xl font-extrabold leading-tight tracking-tighter text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
            >
              We assist our clients in planning and execution in complex and uncertain environments
              to improve safety, mitigate risks & threats, maximize operational efficiency, increase
              overall performance, reduce costs, and finally gain a competitive edge.
            </h1>
          </div>

          <div className='flex justify-center items-center '>
            <a target='_blank' rel='noopener noreferrer' href='https://hello-tomorrow.org/'>
              <LazyLoadImage
                className='py-6 w-24 h-32'
                src={require('../images/hello-tomorrow1.png')}
                width='110'
                height='110'
                alt='Author 04'
              />
            </a>
          </div>

          <h3 className='max-w-4xl mx-auto text-lg font-medium text-center '>
            We have been recognised as a Deep Tech Pioneer out of +4500 companies in 120 countries
            by{' '}
            <a
              class='underline'
              target='_blank'
              rel='noopener noreferrer'
              href='https://hello-tomorrow.org/'
            >
              Hello Tomorrow.
            </a>
          </h3>
          <div className='w-full flex justify-center items-center'>
            <button
              onClick={() => {
                const anchor = document.querySelector('#explore');
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
              className='mt-12 text-white bg-purple-600 btn-sm hover:bg-purple-700'
            >
              <span className='text-sm'>Get Started</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroFeatures;
