import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const features = [
  {
    name: 'Strategic Foresight ',
    description: [
      `Human-Machine Interactions `,
      `Hybrid Intelligence Driven Predictions`,
      `Sophisticated Analytics`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/strategic-foresight.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Governance, Risk and Compliance ',
    description: [
      `Privacy Preserving Data Management`,
      `Predictive Risk Rating`,
      `Early Warning & Alerting`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/governance-risk-compliance.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Smart Government and Business Operations ',
    description: [
      `Privacy Preserving Data Integration`,
      `Automated Reasoning`,
      `Multiple Futures Simulation`,
      `Hybrid Intel-Powered Red Teaming`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/smart-government-business-operations.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
  {
    name: 'Smart Security and Policing',
    description: [
      `Privacy Preserving Data Management`,
      `Real Time Threat & Risk Monitoring`,
      `Risk Prediction and Early Warning`,
      `Smart Counter Operations`,
      `Multiple Futures Simulation`,
    ],
    icon: (
      <LazyLoadImage
        className='flex-shrink-0 rounded-full'
        src={require('../images/smart_lock_Hybrid_Core.png')}
        width='70'
        height='70'
        alt='Author 04'
      />
    ),
  },
];

export default function CoreFeatures() {
  return (
    <div className='relative py-10 border-t border-gray-800 sm:py-12 lg:py-16 '>
      <div className='max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
        <p className='mt-2 h1 text-gradient bg-gradient-to-r from-green-400 to-blue-500 sm:text-4xl'>
        SMART NAVIGATOR CORE USE CASES
        </p>

        <div className='mt-24'>
          <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 '>
            {features.map((feature) => (
              <div key={feature.name} className='pt-6'>
                <div className='flow-root px-6 pb-8'>
                  <div className='-mt-6'>
                    <div>
                      <span className='inline-flex items-center justify-center rounded-md '>
                        {feature.icon}
                      </span>
                    </div>
                    <h3 className='mt-8 text-lg font-medium text-white'>{feature.name}</h3>
                     </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
