import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import BlogList from '../partials/BlogList';
import Footer from '../partials/Footer';
import HeroBlog from '../partials/HeroBlog';

function Blog() {
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page illustration */}
        <div className='relative h-0 max-w-6xl mx-auto pointer-events-none' aria-hidden='true'>
          <PageIllustration />
        </div>
        <HeroBlog />
        {/*  Page sections */}
        <BlogList />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Blog;
