import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

export const FeaturedArticle = ({ title, excerpt, image, profileImage, author, date, slug }) => {
  let match = useRouteMatch();

  return (
    <div className='pb-12 md:pb-20'>
      <article className='max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center'>
        <Link
          to={`${match.path}/${slug}`}
          className='relative block group'
          data-aos='fade-right'
          data-aos-delay='200'
        >
          <div
            className='absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none'
            aria-hidden='true'
          ></div>
          <figure className='relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out'>
            <img
              className='absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out'
              src={require(`../images/${image}`)}
              width='540'
              height='303'
              alt={title}
            />
          </figure>
        </Link>
        <div data-aos='fade-left' data-aos-delay='200'>
          <header>
            <h3 className='h3 text-2xl lg:text-3xl mb-2'>
              <Link
                to={`${match.path}/${slug}`}
                className='hover:text-gray-100 transition duration-150 ease-in-out'
              >
                {title}
              </Link>
            </h3>
          </header>
          <p className='text-lg text-gray-400 flex-grow'>{excerpt}</p>
          <footer className='flex items-center mt-4'>
            <Link to={`/${match.path}/${slug}`}>
              <img
                className='rounded-full flex-shrink-0 mr-4'
                src={require(`../images/team/${profileImage}`)}
                width='40'
                height='40'
                alt={profileImage}
              />
            </Link>
            <div>
              <Link
                to={`/${match.path}/${slug}`}
                className='font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out'
              >
                {author}
              </Link>
              <span className='text-gray-700'> - </span>
              <span className='text-gray-500'>{date}</span>
            </div>
          </footer>
        </div>
      </article>
    </div>
  );
};
