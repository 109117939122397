import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Blog from './pages/Blog';
import Team from './pages/Team';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';
import BlogSingle from './partials/BlogSingle';
import Announcement from './partials/Announcement';
import InterviewWithCEO from './partials/InterviewWithCEO';
import SmartSonarDemo from './pages/SmartSonarDemo';
import SmartNavigator from './pages/SmartNavigator';
import SmartSonar from './pages/SmartSonar';
import SmartPredictivePolicing from './pages/SmartPredictivePolicing';
import HybridIntelligenceinUse from './partials/HybridIntelligenceinUse';
import Ai4kids from './pages/Ai4kids';
import RequestDemo from './pages/RequestDemo';
import Project from './partials/Project';
import Header from './partials/Header';
// import Flip from './pages/Flip';

function App() {
  const location = useLocation();
  const projects = [
    { name: 'KT4D', link: 'kt4d' },
    { name: 'Eurohub4sino', link: 'eurohub4sino' },
  ];
  let logosClient = [
    {
      link: 'https://www.behorizon.org/',
      source: require('./images/partners/bth.png'),
      caption: '',
    },
    {
      link: 'https://horizonglobalacademy.eu/',
      source: require('./images/partners/hga.png'),
      caption: '',
    },
    {
      link: 'https://ircp.ugent.be/',
      source: require('./images/partners/irpc.png'),
      caption: '',
    },
    {
      link: '',
      source: require('./images/partners/ai4eu.webp'),
      caption: '',
    },
    {
      link: 'https://business.ngi.eu/',
      source: require('./images/partners/ngi.png'),
      caption: 'Business Accelerator',
    },
    {
      link: 'https://www.ngi.eu/ngi-projects/ngi-explorers/',
      source: require('./images/partners/ngi-explorer.png'),
      caption: '',
    },
    {
      link: 'https://ec.europa.eu/digital-single-market/en/policies/next-generation-internet',
      source: require('./images/partners/ioh.png'),
      caption: '',
    },
    {
      link: 'https://worldwide.erau.edu/',
      source: require('./images/partners/emb.jpeg'),
      caption: '',
    },
    {
      link: 'https://www.ai4belgium.be/',
      source: require('./images/partners/ai4belgiumlogo.webp'),
      caption: '',
    },
    {
      link: 'https://www.digitalsme.eu/45-digital-smes-support-founding-of-the-european-green-digital-coalition-at-digital-day-2021/',
      source: require('./images/partners/european-digital-sme-alliance.png'),
      caption: '',
    },
    {
      link: 'https://code.ulb.ac.be/lab/IRIDIA',
      source: require('./images/partners/iridia.jpg'),
      caption: '',
    },
    {
      link: 'https://www.njit.edu/',
      source: require('./images/partners/njit-logo.png'),
      caption: '',
    },
    {
      link: 'https://globalethics.ai/',
      source: require('./images/partners/Global_AI_Ethics_Enstitute.jpg'),
      caption: '',
    },
    {
      link: 'https://www.uantwerpen.be/en/research-groups/clips/',
      source: require('./images/partners/universiteit antwerpen.jpg'),
      caption: '',
    },
    {
      link: 'https://interacademy.info/en/international-information-academy/',
      source: require('./images/partners/International Information Academy_logo.jpg'),
      caption: '',
    },
    {
      link: 'https://euhybnet.eu/',
      source: require('./images/partners/eu hybnet logo Hybrid Core.png'),
      caption: '',
    },
    {
      link: 'https://sap.io',
      source: require('./images/SAP-Logo-2000.png'),
      caption: 'SAP.iO Startup Program and Co-Innovation',
    },
  ];
  let projectPartners = [
    {
      link: 'https://www.behorizon.org/',
      source: require('./images/partners/bth.png'),
      caption: 'Beyond the Horizon ISSG (Belgium)',
    },
    {
      link: 'https://hybridcore.eu/',
      source: require('./images/HYBIRD-CORE-3D.webp'),
      caption: 'Hybrid Core (Belgium)',
    },
    {
      link: 'https://www.isdp.eu/',
      source: require('./images/isdp-logo-full-European-Hub-for-Contemporary-China.webp'),
      caption: 'Institutet for Sakerhets-Och Utvecklingspolitik (Sweden)',
    },
    {
      link: 'https://www.vse.cz/english/',
      source: require('./images/VSE_logo-European-Hub-for-Contemporary-China.webp'),
      caption: 'Vysoka Skola Ekonomicka V Praze (Czech Republic)',
    },
    {
      link: 'https://www.uni-heidelberg.de/en',
      source: require('./images/UHEI-logo.webp'),
      caption: 'Ruprecht-Karls-Universitaet Heidelberg (Germany)',
    },
    {
      link: 'https://en.uj.edu.pl/en',
      source: require('./images/jagiellonian-university-logo.webp'),
      caption: 'Uniwersytet Jagiellonski (Poland)',
    },
    {
      link: 'https://warwick.ac.uk/',
      source: require('./images/WarwickLogo.webp'),
      caption: 'The University of Warwick (United Kingdom)',
    },
    {
      link: 'https://www.chathamhouse.org/',
      source: require('./images/chatham-house-logo.webp'),
      caption: 'The Royal Institute Of International Affairs - Chatham House (United Kingdom)',
    },
  ];
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Header projects={projects} />
      <Switch>
        <Route exact path='/'>
          <Home logosClient={logosClient} />
        </Route>
        <Route path='/blog/hybrid-intelligence-in-decision-support-and-use-case'>
          <HybridIntelligenceinUse />
        </Route>
        <Route path='/blog/hybrid-intelligence-startup'>
          <BlogSingle />
        </Route>

        <Route path='/blog/interview-with-our-ceo'>
          <InterviewWithCEO />
        </Route>
        <Route path='/blog/smart-navigator-announcement'>
          <Announcement />
        </Route>
        <Route path='/smart-sonar'>
          <SmartSonarDemo />
        </Route>

        {projects.map((project) => (
          <Route key={project.link} path={`/projects/:projectName`}>
            <Project projectPartners={projectPartners} />
          </Route>
        ))}

        <Route path='/digital-platforms/smart-navigator'>
          <SmartNavigator />
        </Route>
        <Route path='/digital-platforms/ai4kids'>
          <Ai4kids />
        </Route>
        <Route path='/digital-platforms/smart-sonar'>
          <SmartSonar />
        </Route>
        <Route path='/digital-platforms/smart-predictive-policing'>
          <SmartPredictivePolicing />
        </Route>
        <Route path='/blog'>
          <Blog />
        </Route>
        <Route path='/team'>
          <Team />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route path='/request-demo'>
          <RequestDemo />
        </Route>
        {/* <Route path='/flip'>
          <Flip />
        </Route> */}
        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
