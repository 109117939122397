import React from 'react';
import { PowerIcon, PhoneIcon } from '@heroicons/react/outline';

function Eurohub4sinoComponent() {
  return (
    <article>
      <div className='flex items-center text-base text-indigo-50 mb-12'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6 mr-2 md:w-6 md:h-6 md:mr-3' // Add a margin to separate the icon from the text
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z'
          />
        </svg>
        <span className='text-sm text-gray-400'>
          This project is invited for grant agreement preparations. You’ll find regular updates on
          this page.
        </span>
      </div>

      <div className='text-lg text-gray-400'>
        <p className=''>
          China is a key global actor and a leading economic and technological power. China’s
          increasing relevance in the geopolitical system, its various specificities, complexities
          and goals as well as its rapid evolution and new policy directions pose challenge to the
          EU’s capacity to better understand contemporary China. Furthermore, the European Union
          needs to get to know and understand China on its own terms: not through the lens of other
          external sources but from its own European perspective. .
        </p>
        <p className='mb-8'>
          However, the European level expertise on contemporary China is often fragmented and
          difficult to access. To overcome this challenge, it is essential to connect established
          and emerging knowledge nodes as well as gathering, categorising, and mainstreaming their
          work on a publicly accessible platform. This approach will break down siloes and provide
          policy-makers, researchers, businesses, and civil society with the necessary knowledge to
          develop a comprehensive understanding of the various aspects that define contemporary
          China. Such an approach will also enhance the dissemination of information and facilitate
          the generation of new knowledge.
        </p>

        <p className='mb-8'>
          In this regard, EuroHub4Sino project is built upon four interconnected and interdependent
          pillars that foresees (a) to develop a state- of-the-art digital platform which was absent
          in previous projects/initiatives as they had to rely on their own resources in their
          research/cooperation efforts. This platform will also better serve the wider civil society
          in mainstreaming independent European knowledge. An inclusive and diverse (b) network will
          make the most of the digital platform in its (c) collaborative research and communication
          activities. EuroHub4Sino has (d) developed an innovative and sustainable business model
          with an aim that this inclusive network and enhanced digital platform will continue
          working following the end of the project.
        </p>

        <p className='mb-8'>
          <b>Funder:</b>&nbsp; &nbsp; European Commission (Horizon Europe)
        </p>
      </div>
    </article>
  );
}

export default Eurohub4sinoComponent;
