import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../utils/Dropdown';

function Header({ projects }) {
  console.log('header', projects);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className='absolute z-30 w-full'>
      <div className='max-w-7xl px-4 mx-auto sm:px-6'>
        <div className='flex items-center justify-between h-20'>
          {/* Site branding */}
          <div className='flex items-center flex-shrink-0 '>
            {/* Logo */}
            <Link to='/' aria-label='Cruip'>
              <img
                className='w-auto mt-3 h-28 sm:h-32 md:h-48 '
                src={require('../images/logo.png')}
                alt='site logo'
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className='hidden lg:flex lg:flex-grow'>
            {/* Desktop menu links */}
            <ul className='flex flex-wrap items-center justify-end flex-grow'>
              <li>
                <Link
                  to='/'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Home
                </Link>
              </li>

              <Dropdown title='Digital Platforms'>
                {/* 2nd level: hover */}
                <li>
                  <Link
                    to='/digital-platforms/smart-navigator'
                    className='flex px-4 py-2 text-sm font-medium leading-tight text-gray-400 hover:text-purple-600'
                  >
                    Smart Navigator
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to='/digital-platforms/smart-sonar'
                    className='flex px-4 py-2 text-sm font-medium leading-tight text-gray-400 hover:text-purple-600'
                  >
                    Smart Sonar
                  </Link>
                </li>
                <li>
                  <Link
                    to='/digital-platforms/smart-predictive-policing'
                    className='flex px-4 py-2 text-sm font-medium leading-tight text-gray-400 hover:text-purple-600'
                  >
                    Smart Predictive Policing
                  </Link>
                </li> */}
              </Dropdown>
              <Dropdown title='Projects'>
                {projects.map((project) => (
                  <li key={project.link}>
                    <Link
                      to={`/projects/${project.link}`}
                      className='flex px-4 py-2 text-sm font-medium leading-tight text-gray-400 hover:text-purple-600'
                    >
                      {project.name}
                    </Link>
                  </li>
                ))}
              </Dropdown>
              <li>
                <Link
                  to='/smart-sonar'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Smart Sonar
                </Link>
              </li>
              <li>
                <Link
                  to='/blog'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to='/team'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Team
                </Link>
              </li>
              <li>
                <a
                  href='https://behorizon.org/horizon-lab/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Horizon Lab
                </a>
              </li>
              <li>
                <Link
                  to='/request-demo'
                  className='flex items-center px-4 py-2 text-gray-300 transition duration-150 ease-in-out hover:text-gray-200'
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className='lg:hidden'>
            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls='mobile-nav'
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className='sr-only'>Menu</span>
              <svg
                className='w-6 h-6 text-gray-300 transition duration-150 ease-in-out fill-current hover:text-gray-200'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect y='4' width='24' height='2' rx='1' />
                <rect y='11' width='24' height='2' rx='1' />
                <rect y='18' width='24' height='2' rx='1' />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id='mobile-nav'
              ref={mobileNav}
              className='absolute left-0 z-20 w-full px-4 overflow-hidden transition-all duration-300 ease-in-out top-full sm:px-6'
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className='px-4 py-2 bg-gray-800'>
                <li>
                  <Link to='/' className='flex py-2 text-gray-300 hover:text-gray-200'>
                    Home
                  </Link>
                </li>

                <li>
                  <Link to='/blog' className='flex py-2 text-gray-300 hover:text-gray-200'>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to='/smart-sonar' className='flex py-2 text-gray-300 hover:text-gray-200'>
                    Smart Sonar
                  </Link>
                </li>

                <li className='py-2 my-2 border-t border-b border-gray-700'>
                  <span className='flex py-2 text-gray-300'>Digital Platforms</span>
                  <ul className='pl-4'>
                    <li>
                      <Link
                        to='/digital-platforms/smart-navigator'
                        className='flex py-2 text-sm font-medium text-gray-400 hover:text-gray-200'
                      >
                        Smart Navigator
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/digital-platforms/smart-predictive-policing'
                        className='flex py-2 text-sm font-medium text-gray-400 hover:text-gray-200'
                      >
                        Smart Predictive Policing
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to='/digital-platforms/smart-sonar'
                        className='flex py-2 text-sm font-medium text-gray-400 hover:text-gray-200'
                      >
                        Smart Sonar
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/digital-platforms/smart-predictive-policing'
                        className='flex py-2 text-sm font-medium text-gray-400 hover:text-gray-200'
                      >
                        Smart Predictive Policing
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li className='py-2 my-2 border-b border-gray-700'>
                  <span className='flex py-2 text-gray-300'>Projects</span>
                  <ul className='pl-4'>
                    {projects.map((project) => (
                      <li key={project.link}>
                        <Link
                          to={`/projects/${project.link}`}
                          className='flex py-2 text-sm font-medium text-gray-400 hover:text-gray-200'
                        >
                          {project.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <a
                    href='https://behorizon.org/horizon-lab/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex py-2 text-gray-300 hover:text-gray-200'
                  >
                    Horizon Lab
                  </a>
                </li>
                <li>
                  <Link to='/team' className='flex py-2 text-gray-300 hover:text-gray-200'>
                    Team
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
