import React from 'react';

import { FeaturedArticle } from '../partials/FeaturedArticle';
import { FeaturedInterview } from '../partials/FeaturedInterview';
import CircleIllustration from './CircleIllustration';
function BlogList() {
  return (
    <section className='relative'>
      <div className='max-w-6xl px-4 mx-auto sm:px-6'>
        <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
          <FeaturedArticle
            title={`Next Generation Internet (NGI) Explorers - Interview with our CEO`}
            image={`Next Generation Internet interview with our CEO.jpg`}
            profileImage={`logo.png`}
            author={`Hybrid Core`}
            date={`Dec 8, 2021`}
            slug={`interview-with-our-ceo`}
          />
          <FeaturedArticle
            title={`Hybrid Intelligence in Decision Support and a Use Case: Predictive Crisis Management`}
            excerpt={`How to address AI challenges in decision technologies`}
            image={`Predictive Crisis Management_Featured Hybrid Core .jpg`}
            profileImage={`hasan_suzen.png`}
            author={`Hasan SUZEN`}
            date={`June 8, 2021`}
            slug={`hybrid-intelligence-in-decision-support-and-use-case`}
          />
          <FeaturedInterview
            title={` Next Generation Internet (NGI) Explorers - Interview with our CEO`}
            image={`Next Generation Internet interview with our CEO.jpg`}
            date={`Feb 19, 2021`}
          />
          <FeaturedArticle
            title={`Smart Navigator Announcement`}
            excerpt={`We are happy to announce that our project, Smart Navigator which provides
            next-generation decision technology, is awarded as one of top exploration by the
            Next Generation Internet Explorers Team.`}
            image={`announcement.jpeg`}
            profileImage={`logo.png`}
            author={`Hybrid Core`}
            date={`Oct 13, 2020`}
            slug={`smart-navigator-announcement`}
          />
          <div
            className='absolute hidden pointer-events-none lg:block'
            aria-hidden='true'
            data-aos='fade-up'
          >
            <CircleIllustration
              className={'max-w-full'}
              width={'450'}
              height={'480'}
              fill={'url(#illustration-02)'}
            />
          </div>
          <FeaturedArticle
            title={`Hybrid intelligence startup cooperation with governments and companies in the US and
            Europe?`}
            excerpt={`Even though the American scientist was one of the fathers of meteorological
            predictability, he did not have enough technical facilities to be not so rigorous
            concerning chaos...`}
            image={`startup-cooperation.jpg`}
            profileImage={`hasan_suzen.png`}
            author={`Hasan SUZEN`}
            date={`Sept 13, 2020`}
            slug={`hybrid-intelligence-startup`}
          />
        </div>
      </div>
    </section>
  );
}

export default BlogList;
