import React from 'react';

import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

function Clients(props, { scrollPosition }) {
  console.log('home client', props);
  const titleLogos = 'HYBRID CORE TRUST ECOSYSTEM';
  const titleProjectPartners = 'Project Partners';
  return (
    <section>
      <div className='max-w-6xl px-4 mx-auto bg-white sm:px-6'>
        <div className='py-12 md:py-20'>
          {/* Section header */}
          <div className='max-w-3xl pb-12 mx-auto text-center text-gray-700 md:pb-16 '>
            <h1 className='mb-4 h2 text-gradient bg-gradient-to-r from-green-400 to-blue-500'>
              {props.logos ? titleLogos : titleProjectPartners}
            </h1>
            <div className='w-full  h-0.5 divider' />
          </div>
          <div className='mx-auto grid max-w-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none '>
            {props.logos &&
              props.logos.map((logo) => {
                return (
                  <div className='flex flex-col items-center justify-center'>
                    <a target='_blank' rel='noopener noreferrer' href={logo.link}>
                      <LazyLoadImage
                        src={logo.source}
                        alt={logo.caption}
                        scrollPosition={scrollPosition}
                        className='col-span-2 w-[150px] max-h-[100px] object-contain lg:col-span-1 aspect-[1/1]'
                      />
                    </a>
                    {logo.caption && (
                      <span className='text-xs leading-none text-center text-black'>
                        {logo.caption}
                      </span>
                    )}
                  </div>
                );
              })}
            {props.projectPartners &&
              props.projectPartners.map((logo) => {
                return (
                  <div className='flex flex-col items-center justify-center'>
                    <a target='_blank' rel='noopener noreferrer' href={logo.link}>
                      <LazyLoadImage
                        src={logo.source}
                        alt={logo.caption}
                        scrollPosition={scrollPosition}
                        className='col-span-2 w-[150px] max-h-[100px] object-contain lg:col-span-1 aspect-[1/1]'
                      />
                    </a>
                    {logo.caption && (
                      <span className='text-xs mt-4 leading-none text-center text-black'>
                        {logo.caption}
                      </span>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default trackWindowScroll(Clients);
