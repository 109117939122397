import React from 'react';
import { Link } from 'react-router-dom';

import Wave from './Wave';
const svgBgColor = '#FFFFFF';

function Footer() {
  return (
    <footer id='contact' className='pt-12'>
      <Wave footerSvg={svgBgColor} />
      <div className='pt-12 bg-white md:py-8'>
        <div className='max-w-6xl px-4 mx-auto sm:px-6 '>
          {/* Top area: Blocks */}
          <div className='grid gap-8 md:grid-cols-12 lg:gap-20'>
            {/* 1st block */}
            <div className='sm:col-span-6 '>
              <div className='mb-2'>
                {/* Logo */}
                <Link to='/' aria-label='Cruip'>
                  <img
                    className='text-purple-600 fill-current w-18 h-18'
                    src={require('../images/logo.png')}
                    width='300'
                    height='300'
                    alt='footer'
                    loading='lazy'
                  />
                </Link>
              </div>
              <div className='text-gray-700'>
                Da Vincilaan 1, 1930 Zaventem Belgium <br />
                Tel:+32 (0) 2 801 13 05 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+32 (0) 2 801 13 57 <br />
                Email: info@hybridcore.eu <br />
                BTW: BE0752.691.888 <br />
                duns® number: 372159584 <br />
                NCAGE Code: BTSJ9
              </div>
            </div>
            <div className='sm:col-span-6'>
              <div className='mb-2'>
                {/* Logo */}
                <Link to='/' className='inline-block ' aria-label='Cruip'>
                  <img
                    className='text-purple-600 fill-current w-18 h-18'
                    src={require('../images/european-commision.png')}
                    width='300'
                    height='300'
                    alt='footer'
                    loading='lazy'
                  />
                </Link>
              </div>
              <div className='text-gray-700'>InvestEU Portal Smart Navigator (INPO-20211878)</div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className='grid gap-8 md:col-span-12 lg:col-span-12 sm:grid-cols-3'>
              <div className='flex flex-col text-sm justify-evenly'>
                <Link to='/' className='inline-block ' aria-label='Cruip'>
                  <h6 className='mr-1 font-medium text-gray-700 hover:text-purple-600'>Home</h6>
                </Link>

                <Link to='/blog' className='inline-block' aria-label='Cruip'>
                  <h6 className='mr-1 font-medium text-gray-700 hover:text-purple-600'>Blog</h6>
                </Link>
                <Link to='/team' className='inline-block' aria-label='Cruip'>
                  <h6 className='mr-1 font-medium text-gray-700 hover:text-purple-600'>Team</h6>
                </Link>
              </div>

              {/* 2nd block */}
              <div className='text-sm'>
                <h6 className='mb-1 font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50'>
                  Digital Platforms
                </h6>
                <ul>
                  <li className='mb-1'>
                    <Link
                      to='/digital-platforms/smart-navigator'
                      className='text-gray-500 transition duration-150 ease-in-out hover:text-purple-600'
                    >
                      Smart Navigator
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='text-sm'>
                <Link to='/smart-sonar'>
                  <h6 className='mb-1 font-medium text-gray-700  hover:text-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50'>
                    Smart Sonar
                  </h6>
                </Link>
              </div>
              {/* 3rd block */}
              {/* <div className='text-sm'>
                <h6 className='mb-1 font-medium text-gray-700'>Demos</h6>
                <ul>
                  <li className='mb-1'>
                    <Link
                      to='/smart-sonar'
                      className='text-gray-500 transition duration-150 ease-in-out hover:text-purple-600'
                    >
                      Smart Sonar
                    </Link>
                  </li>
                </ul>
              </div> */}

              {/* 4th block */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
