import React from 'react';

function TeamCard(props) {
  const { path, name, position } = props.member;
  return (
    <div>
      <li>
        <div className='space-y-4'>
          <img
            className='w-32 h-32 mx-auto rounded-full lg:w-36 lg:h-36'
            src={require(`../images/team/${path}`)}
            alt=''
          />
          <div className='space-y-2'>
            <div className='font-medium lg:text-lg'>
              <h3>{name}</h3>
              <p className='text-gray-500'>{position}</p>
            </div>
          </div>
        </div>
      </li>
    </div>
  );
}

export default TeamCard;
