import React, { useState, useRef, useEffect } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Transition from '../utils/Transition.js';
import Train_Advise_and_Assist_Services from '../../src/Train_Advise_and_Assist_Services.pdf';
function Tabs() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px';
    }
  };

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  function openTab(tabIndex) {
    if (tabIndex === 3) {
      window.open(Train_Advise_and_Assist_Services);
    }
    return setTab(tabIndex);
  }
  function ButtonTab({ title, tabIndex, svgPath }) {
    return (
      <button
        className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${
          tab !== tabIndex && 'opacity-50'
        }`}
        onClick={() => openTab(tabIndex)}
      >
        <svg
          className='w-4 h-4 mr-2 text-purple-600 fill-current'
          viewBox='0 0 16 16'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d={svgPath} />
        </svg>

        <span className='text-gray-400 transition-colors duration-150 ease-in-out group-hover:text-gray-200'>
          {title}
        </span>
      </button>
    );
  }

  return (
    <section id='explore'>
      <div className='max-w-6xl px-4 mx-auto sm:px-6'>
        <div className='py-12 border-t border-gray-800 md:py-20'>
          {/* Section header */}
          <div className='max-w-3xl pb-12 mx-auto text-center' data-aos-id-tabs>
            <h2
              className='mb-4 h2 text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
              data-aos-anchor='[data-aos-id-tabs]'
            >
              EXPLORE OUR PRODUCT AND SERVICES
            </h2>
            <p
              className='text-xl text-gray-400'
              data-aos='fade-up'
              data-aos-delay='200'
              data-aos-anchor='[data-aos-id-tabs]'
            >
              Protect and Shape Your Future in Partnership with Hybrid Core
            </p>
          </div>

          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div className='flex flex-wrap justify-center -m-2'>
              <ButtonTab
                title={'Smart Navigator'}
                tabIndex={1}
                svgPath={
                  'M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z'
                }
              />
              <ButtonTab
                title={'Train, Advise, Assist'}
                tabIndex={3}
                svgPath={
                  'M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z'
                }
              />
              <ButtonTab
                title={'Consultancy (Strategic Foresight Analysis)'}
                tabIndex={2}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />

              <ButtonTab
                title={'Consultancy (AI-Powered Red Teaming)'}
                tabIndex={6}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'AI4Kids'}
                tabIndex={5}
                svgPath={'M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z'}
              />
            </div>

            {/* Tabs items */}
            <div className='relative flex flex-col md:mt-16' data-aos='fade-up' ref={tabs}>
              {/* Item 1 */}
              <Transition
                show={tab === 1}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-48'
                      src={require('../images/nav.webp')}
                      alt='Tabs 01'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-xl'>
                    <h4 className='mb-2 h4'>Smart Navigator</h4>
                    <p className='text-lg text-gray-400'>
                      Secure & Integrated Decision, Intelligence, and Operation Management System.
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='/digital-platforms/smart-navigator'
                    >
                      <span className='text-sm'>Learn more</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 6 */}
              <Transition
                show={tab === 2}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-80'
                      src={require('../images/strategic-foresight.webp')}
                      alt='Tabs 01'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-xl'>
                    <h4 className='mb-2 h4'>Strategic Foresight Analysis</h4>
                    <p className='text-lg text-gray-400'>
                      Strategic Foresights produced by cross-functional human-machine team. Reports
                      are concise, to do point, easy to digest, full of data driven predictions and
                      simulations generated by Smart Navigator and validated by human experts.
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a Demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 3
              <Transition
                show={tab === 3}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:left-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-48'
                      src={require('../images/son.webp')}
                      alt='Tabs 02'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto'>
                    <h4 className='mb-2 h4'>Smart Sonar</h4>
                    <p className='text-lg text-gray-400'>
                      Transforms big data into targeted marketing & sales
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='/digital-platforms/smart-sonar'
                    >
                      <span className='text-sm'>Learn more</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition> */}

              {/* Item 4
              <Transition
                show={tab === 4}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-48'
                      src={require('../images/pre.png')}
                      alt='Tabs 01'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-xl'>
                    <h4 className='mb-2 h4'>Smart Predictive Policing</h4>
                    <p className='text-lg text-gray-400'>
                      Transforms big data into safety & security.
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='/digital-platforms/smart-predictive-policing'
                    >
                      <span className='text-sm'>Learn more</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition> */}

              {/* Item 5 */}
              <Transition
                show={tab === 5}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:left-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-48'
                      src={require('../images/AI4Kids.webp')}
                      alt='Tabs 02'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto'>
                    <h4 className='mb-2 h4'>AI4Kids</h4>
                    <p className='text-lg text-gray-400'>
                      AI4Kids project develops a novel methodology in science, technology,
                      engineering and mathematics (STEM) Education, the teaching of Artificial
                      Intelligence.
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='/digital-platforms/ai4kids'
                    >
                      <span className='text-sm'>Learn more</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 6 */}
              <Transition
                show={tab === 6}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <LazyLoadImage
                      className='object-contain w-full h-48'
                      src={require('../images/apart.webp')}
                      alt='Tabs 01'
                    />
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-xl'>
                    <h4 className='mb-2 h4'>
                      Artificial Intelligence Powered Advanced Red Teaming
                    </h4>
                    <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p>
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://aipoweredredteaming.com/'
                    >
                      <span className='text-sm'>Learn more</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
