import React, { useState } from 'react';
import Modal from '../utils/Modal';
import CircleIllustration from './CircleIllustration';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import loadable from '@loadable/component';

const Youtube = loadable(() => import('../utils/Youtube'));

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section>
      <div className='relative max-w-6xl px-4 mx-auto sm:px-6'>
        {/* Illustration behind hero content */}

        <div
          className='absolute bottom-0 left-0 hidden -ml-20 pointer-events-none lg:block'
          aria-hidden='true'
          data-aos='fade-up'
          data-aos-delay='400'
        >
          <CircleIllustration
            className={'max-w-full'}
            width={'564'}
            height={'552'}
            fill={'url(#illustration-02)'}
          />
        </div>

        {/* Hero content */}
        <div className='relative pt-32 pb-10 md:pt-40 md:pb-16'>
          {/* Section header */}
          <div className='max-w-3xl pb-12 mx-auto text-center md:pb-16'>
            <h1
              className='mb-4 h1 text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
            >
              NEXT-GENERATION DECISION INTELLIGENCE
            </h1>
            <p className='mt-8 mb-8 text-xl text-gray-400' data-aos='fade-up' data-aos-delay='200'>
            Decision-making and operation management have fundamentally changed in the digital 
            transformation era. While human or machine-only approaches are increasingly inadequate; 
            trustworthiness, security, accuracy, agility, speed and end-to-end solutions become profoundly
             important in the digitalisation transformation era.
            </p>
            <h1
              className='mb-4 h1 text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
            >
              OUR SOLUTION
            </h1>
            <p className='mt-8 mb-8 text-xl text-gray-400' data-aos='fade-up' data-aos-delay='200'>
            With the perfect combination of post-quantum security and next-generation smart decision, 
            intelligence, and operation systems, Hybrid Core (HC) navigates government bodies and 
            mid-large-size companies to long-term resilience and lasting success in data-rich, 
            hyper-connected, and complex operation environments. 
            </p>
            <p className='mt-8 mb-8 text-xl text-gray-400' data-aos='fade-up' data-aos-delay='200'>
            As a recognised deep tech pioneer, HC presents an all-in-one comprehensive solution 
            platform, Smart Navigator, which brings together big data, post-quantum encryption, 
            hybrid AI, VR/AR, dynamic simulation, and cyber security fundamental features.           
            </p>
            <p className='mt-8 mb-8 text-xl text-gray-400' data-aos='fade-up' data-aos-delay='200'>
            HC also provides train, advise, and assist services in strategic and digital transformation 
            and consultancy services in strategic foresight analysis and AI-powered red teaming.
            </p>
          </div>

          {/* Hero image */}
          <div>
            <div
              className='relative flex items-center justify-center'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <LazyLoadImage
                className='mx-auto rounded-full'
                src={require('../images/banner2.webp')}
                width='1024'
                height='504'
                alt='Hero'
              />
              <a
                className='absolute group'
                href='#0'
                onClick={(e) => {
                  e.preventDefault();
                  setVideoModalOpen(true);
                }}
                aria-controls='modal'
              >
                <svg
                  className='w-16 h-16 transition duration-150 ease-in-out sm:w-20 sm:h-20 hover:opacity-75'
                  viewBox='0 0 88 88'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <defs>
                    <linearGradient x1='78.169%' y1='9.507%' x2='24.434%' y2='90.469%' id='a'>
                      <stop stopColor='#EBF1F5' stopOpacity='.8' offset='0%' />
                      <stop stopColor='#EBF1F5' offset='100%' />
                    </linearGradient>
                  </defs>
                  <circle fill='url(#a)' cx='44' cy='44' r='44' />
                  <path
                    className='text-purple-600 fill-current'
                    d='M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z'
                  />
                </svg>
              </a>
            </div>

            {/* Modal */}

            <Modal
              id='modal'
              ariaLabel='modal-headline'
              show={videoModalOpen}
              handleClose={() => setVideoModalOpen(false)}
            >
              <div className='relative pb-9/16'>
                <Youtube fallback={<div>Loading...</div>} />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
