import React, { useState, useRef, useEffect } from 'react';

import Wave from './Wave';
import Transition from '../utils/Transition.js';

function SmartSonarCarousel() {
  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);
  const [items] = useState([
    {
      quote: `Intelligent marketing & sales is dynamically developing and aims at optimizing the effort of sales and marketing departments. An excellent tool for that is Smart Sonar. Big Data is employed in designing customer journey. Hybrid intelligence allows to collect, process and utilize Big Data. They consist of the in-built mechanisms and algorithms that let us predict potential interest, future patterns, and more importantly cognitive architecture of customers.
      `,
    },
    {
      quote: `Smart Sonar focuses on analysing the data of customers, finds interconnectivity and correlations between all their interactions leading to the customer journey. It delivers dynamic personalized content and offers all channels; in other words, it optimizes customer acquisition and maximizes marketing campaigns.`,
    },
    {
      quote: `This cutting-edge tool provides insight into customer purchase history, buyer’s journey, personalized product recommendations according to what the algorithm deems most likely to be bought, and analyses the way products correlate in categories, allowing for highly engaging and eye-catching offers to be delivered to targeted clients.`,
    },
  ]);

  const testimonials = useRef(null);

  const stopAutorotate = () => {
    setAutorotate(null);
  };

  const heightFix = () => {
    if (testimonials.current.children[active]) {
      testimonials.current.style.height = testimonials.current.children[active].offsetHeight + 'px';
    }
  };

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : (active) => active + 1);
    }, autorotateTiming);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, autorotate]);

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <>
      <section className='relative'>
        {/* Background image */}
        <div className='absolute inset-0'>
          <img
            className='w-full h-full object-cover'
            src={require('../images/worldmap.jpg')}
            width='1440'
            height='394'
            alt='About'
          />
          <div className='absolute inset-0 bg-gray-900 opacity-25' aria-hidden='true'></div>
        </div>

        {/* Hero content */}
        <div className='max-w-6xl mx-auto px-4 sm:px-6 relative'>
          <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
            <div className='max-w-3xl mx-auto text-center'>
              <h1
                className='h1 mb-4 text-gradient  bg-gradient-to-r from-green-400 to-blue-500'
                data-aos='fade-up'
              >
                HOW DOES SMART SONAR WORK?
              </h1>
            </div>
          </div>
        </div>
        <Wave />
      </section>
      <section>
        <div className='max-w-6xl mx-auto px-4 sm:px-6'>
          <div className='py-12 md:py-20 '>
            {/* Carousel area*/}
            <div className='max-w-2xl mx-auto pb-12'>
              {/* Carousel */}
              <div className='mt-6 '>
                {/* Testimonials */}
                <div className='relative flex items-start' ref={testimonials}>
                  {items.map((item, index) => (
                    <Transition
                      key={index}
                      show={active === index}
                      appear={true}
                      className='text-center'
                      enter='transition ease-in-out duration-500 transform order-first'
                      enterStart='opacity-0 scale-98'
                      enterEnd='opacity-100 scale-100'
                      leave='transition ease-out duration-300 transform absolute'
                      leaveStart='opacity-100 scale-100'
                      leaveEnd='opacity-0 scale-98'
                    >
                      <blockquote className='text-lg md:text-xl text-gray-400'>
                        {item.quote}
                      </blockquote>
                    </Transition>
                  ))}
                </div>

                {/* Bullets */}
                <div className='flex justify-center mt-6'>
                  {items.map((item, index) => (
                    <button
                      className='p-1 group'
                      key={index}
                      onClick={() => {
                        setActive(index);
                        stopAutorotate();
                      }}
                    >
                      <span
                        className={`block w-2 h-2 rounded-full group-hover:bg-gray-400 transition duration-150 ease-in-out ${
                          active === index ? 'bg-gray-200' : 'bg-gray-500'
                        }`}
                      ></span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {/* Section header */}
            <div
              className='max-w-3xl mx-auto text-center pb-12 md:pb-16'
              data-aos-id-testimonialcar
            >
              <h2
                className='h2 mb-4'
                data-aos='fade-up'
                data-aos-anchor='[data-aos-id-testimonialcar]'
              >
                Data Collected by Smart Sonar from
              </h2>
              <p
                className='text-xl text-gray-400'
                data-aos='fade-up'
                data-aos-delay='200'
                data-aos-anchor='[data-aos-id-testimonialcar]'
              >
                Online Retailers, Social Media Channels, Google/Bing Search, Ranking and Customer
                Services Data, Companies’ Raw Data, and Our Own Data Bases
              </p>
            </div>

            {/* Check list */}
            <div className='max-w-3xl mx-auto pb-16'>
              <ul className='flex flex-col sm:flex-row flex-wrap justify-center items-center text-lg text-gray-400 -mx-3 -my-2'>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='400'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Website visits</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='500'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Products bought</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='600'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Products added to cart</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='700'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Conversion paths</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='800'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Conversion sources</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='900'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Buyers’ trends data</span>
                </li>{' '}
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1000'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Purchase transactions</span>
                </li>{' '}
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1100'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Price history</span>
                </li>{' '}
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1200'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Trend seasons</span>
                </li>{' '}
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1300'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Reactions to direct marketing</span>
                </li>{' '}
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1400'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Search terms used</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1500'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Chat conversations</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1600'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Products displayed</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1700'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Cart value</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1800'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Ranking value</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='1900'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Customers’ opinions (Sentiment analysis, social media interactions)</span>
                </li>
                <li
                  className='flex items-center mx-3 my-2'
                  data-aos='fade-up'
                  data-aos-delay='2000'
                  data-aos-anchor='[data-aos-id-testimonialcar]'
                >
                  <svg
                    className='w-6 h-6 mr-3 flex-shrink-0'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle className='fill-current text-green-500' cx='12' cy='12' r='12' />
                    <path
                      className='fill-current text-white'
                      d='M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z'
                    />
                  </svg>
                  <span>Offline behaviours</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SmartSonarCarousel;
