import React, { useState, useRef, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Img from 'react-cool-img';
import Transition from '../utils/Transition.js';

function SmartNavTabs() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px';
    }
  };

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  function ButtonTab({ title, tabIndex, svgPath }) {
    return (
      <button
        className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${
          tab !== tabIndex && 'opacity-50'
        }`}
        onClick={() => setTab(tabIndex)}
      >
        <svg
          className='w-4 h-4 mr-2 text-purple-600 fill-current'
          viewBox='0 0 16 16'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d={svgPath} />
        </svg>
        <span className='text-gray-400 transition-colors duration-150 ease-in-out group-hover:text-gray-200'>
          {title}
        </span>
      </button>
    );
  }

  return (
    <section>
      <div className='max-w-6xl px-4 mx-auto sm:px-6'>
        <div className='py-12 mt-12 border-t border-gray-800 md:py-20'>
          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div
              className='flex flex-wrap justify-center -m-2'
              data-aos='fade-up'
              data-aos-delay='400'
              data-aos-anchor='[data-aos-id-tabs]'
            >
              <ButtonTab
                title={'Data Analytics'}
                tabIndex={1}
                svgPath={
                  'M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z'
                }
              />
              <ButtonTab
                title={'Focus Vision'}
                tabIndex={2}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'Horizon Scan'}
                tabIndex={3}
                svgPath={
                  'M9.043 6.293S9.79 1.905 6.745 0A5.37 5.37 0 014.72 3.887C3.42 5.03.974 7.6 1 10.34A6.285 6.285 0 004.451 16a3.984 3.984 0 011.394-2.755 3.253 3.253 0 001.246-2.185 5.856 5.856 0 013.1 4.881v.013a5.883 5.883 0 003.428-5.106c.216-2.574-1.194-6.074-2.445-7.218a6.793 6.793 0 01-2.13 2.663z'
                }
              />
              <ButtonTab
                title={'Network Analysis'}
                tabIndex={4}
                svgPath={
                  'M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2 0-.2 0-.3.1-.5L3.6 5 5 3.6l2.5 2.5c.2-.1.3-.1.5-.1 1.1 0 2 .9 2 2s-.9 2-2 2z'
                }
              />
              <ButtonTab
                title={'Predictions'}
                tabIndex={5}
                svgPath={'M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z'}
              />
              <ButtonTab
                title={'Predictive Risk Ratings'}
                tabIndex={6}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'Visual Pattern Recognition'}
                tabIndex={7}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'Pattern Prediction'}
                tabIndex={8}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'Agent-Based Simulation'}
                tabIndex={9}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
              <ButtonTab
                title={'Dynamic Simulation'}
                tabIndex={10}
                svgPath={
                  'M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z'
                }
              />
            </div>

            {/* Tabs items */}
            <div className='relative flex flex-col mt-4 md:mt-16' data-aos='fade-up' ref={tabs}>
              {/* Item 1 */}
              <Transition
                show={tab === 1}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-full'
                        src={require('../images/smart-navigation-tabs/data-analytics.webp')}
                        alt='Tabs 01'
                        width='500'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 mt-6 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-lg'>
                    <h4 className='mb-2 h4'>Data Analytics</h4>
                    {/* <p className='text-lg text-gray-400'>
                      Transforms big data into actionable business intelligence and bespoke
                      intelligent threat & risk analysis.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 2 */}
              <Transition
                show={tab === 2}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='md:pr-16 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-full'
                        src={require('../images/smart-navigation-tabs/focus-vision.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-lg'>
                    <h4 className='mb-2 h4'>Focus Vision</h4>
                    {/* <p className='text-lg text-gray-400'>
                      Strategic Foresights produced by cross-functional human-machine team. Reports
                      are concise, to do point, easy to digest, full of data driven predictions and
                      simulations generated by Smart Navigator and validated by human experts.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 3 */}
              <Transition
                show={tab === 3}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='md:pr-16 md:absolute md:inset-y-0 md:left-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-full '
                        src={require('../images/smart-navigation-tabs/horizon-scan.webp')}
                        alt='Tabs 02'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pl-16 md:max-w-lg lg:max-w-lg md:ml-auto'>
                    <h4 className='mb-2 h4'>Horizon Scan</h4>
                    {/* <p className='text-lg text-gray-400'>
                      Transforms big data into targeted marketing & sales
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 4 */}
              <Transition
                show={tab === 4}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/network-analysis.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-xl'>
                    <h4 className='mb-2 h4'>Network Analysis</h4>
                    {/* <p className='text-lg text-gray-400'>
                      Transforms big data into safety & security.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 5 */}
              <Transition
                show={tab === 5}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:left-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/predictions.webp')}
                        alt='Tabs 02'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pl-16 md:max-w-lg lg:max-w-lg md:ml-auto'>
                    <h4 className='mb-2 h4'>Predictions</h4>
                    {/* <p className='text-lg text-gray-400'>
                      AI4Kids project develops a novel methodology in science, technology,
                      engineering and mathematics (STEM) Education, the teaching of Artificial
                      Intelligence.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>

              {/* Item 6 */}
              <Transition
                show={tab === 6}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/predictive-risk-ratings.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-md'>
                    <h4 className='mb-2 h4'>Predictive Risk Ratings</h4>
                    {/* <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
              {/* Item 7 */}
              <Transition
                show={tab === 7}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/visual-pattern-recognition.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-md'>
                    <h4 className='mb-2 h4'>Visual Pattern Recognition</h4>
                    {/* <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
              {/* Item 8 */}
              <Transition
                show={tab === 8}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/pattern-prediction.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-md'>
                    <h4 className='mb-2 h4'>Pattern Prediction</h4>
                    {/* <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
              {/* Item 9 */}
              <Transition
                show={tab === 9}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/agent-based-simulation.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-md'>
                    <h4 className='mb-2 h4'>Agent-Based Simulation</h4>
                    {/* <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
              {/* Item 10 */}
              <Transition
                show={tab === 10}
                appear={true}
                className='w-full'
                enter='transition ease-in-out duration-500 transform order-first'
                enterStart='opacity-0 scale-98'
                enterEnd='opacity-100 scale-100'
                leave='transition ease-out duration-300 transform absolute'
                leaveStart='opacity-100 scale-100'
                leaveEnd='opacity-0 scale-98'
              >
                <article className='relative max-w-md mx-auto space-y-8 md:max-w-none'>
                  <figure className='py-4 md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                    <Zoom>
                      <Img
                        className='object-contain w-full h-48'
                        src={require('../images/smart-navigation-tabs/dynamic-simulation.webp')}
                        alt='Tabs 01'
                      />
                    </Zoom>
                  </figure>
                  <div className='relative px-6 py-8 bg-gray-800 md:py-16 md:pr-16 md:max-w-lg lg:max-w-md'>
                    <h4 className='mb-2 h4'>Dynamic Simulation</h4>
                    {/* <p className='text-lg text-gray-400'>
                      APART is a genuine red teaming method and originally designed by a diverse
                      group of professionals from a wide spectrum of expertise.
                    </p> */}
                    <a
                      className='mt-6 text-white bg-purple-600 btn-sm hover:bg-purple-700'
                      href='https://hybridcore.eu/request-demo'
                    >
                      <span className='text-sm'>Request a live demo</span>
                      <svg
                        className='flex-shrink-0 w-3 h-3 ml-2 text-purple-400 fill-current'
                        viewBox='0 0 12 12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M6 5H0v2h6v4l6-5-6-5z' />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmartNavTabs;
