import React, { useState } from 'react';
import Modal from '../utils/Modal';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function News() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  return (
    <section>
      <div className='max-w-6xl px-4 mx-auto sm:px-6'>
        <div className='py-12 border-t border-gray-800 md:py-20'>
          {/* Section header */}
          <div className='max-w-3xl pb-12 mx-auto text-center md:pb-20'>
            <h2
              className='h2 text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
            >
              OUR BLOG POSTS
            </h2>
          </div>

          {/* Articles list */}
          <div className='max-w-sm mx-auto md:max-w-none'>
            <div className='grid items-start gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8'>
              {/* 1st article */}

              {/* 2nd article */}
              <article className='flex flex-col h-full' data-aos='fade-up' data-aos-delay='200'>
                <header>
                  <Link className='block mb-6' to={`/blog/interview-with-our-ceo`}>
                    <figure className='relative h-0 overflow-hidden rounded-sm pb-9/16'>
                      <LazyLoadImage
                        className='absolute inset-0 object-cover w-full h-full transition duration-700 ease-out transform hover:scale-105'
                        src={require('../images/announcement.jpeg')}
                        width='352'
                        height='198'
                        alt='News 02'
                      />
                    </figure>
                  </Link>

                  <h3 className='mb-2 h4'>
                    <Link
                      to={`/blog/interview-with-our-ceo`}
                      className='transition duration-150 ease-in-out hover:text-gray-100'
                    >
                      Next Generation Internet (NGI) Explorers - Interview with our CEO
                    </Link>
                  </h3>
                </header>
                {/* <p className='flex-grow text-lg text-gray-400'>
                Next Generation Internet (NGI) Explorers - Interview with our CEO
                </p> */}
                <footer className='flex items-center mt-4'>
                  <LazyLoadImage
                    className='flex-shrink-0 mr-4 rounded-full'
                    src={require('../images/logo-post.png')}
                    width='40'
                    height='40'
                    alt='Author 02'
                  />

                  <div className='flex items-center font-medium text-gray-200 transition duration-150 ease-in-out hover:text-gray-100'>
                    <p>Hybrid Core</p>
                    <span className='text-gray-700'> - </span>
                    <span className='text-gray-500'>Dec 8, 2021</span>
                  </div>
                </footer>
              </article>
              <article className='flex flex-col h-full' data-aos='fade-up'>
                <header>
                  <Link
                    className='block mb-6'
                    to={`/blog/hybrid-intelligence-in-decision-support-and-use-case`}
                  >
                    <figure className='relative h-0 overflow-hidden rounded-sm pb-9/16'>
                      <LazyLoadImage
                        className='absolute inset-0 object-fill w-full h-full transition duration-700 ease-out transform hover:scale-105'
                        src={require('../images/Predictive Crisis Management_Featured Hybrid Core .jpg')}
                        width='352'
                        height='198'
                        alt='News 01'
                      />
                    </figure>
                  </Link>

                  <h3 className='mb-2 h4'>
                    <Link
                      to={`/blog/hybrid-intelligence-in-decision-support-and-use-case`}
                      className='transition duration-150 ease-in-out hover:text-gray-100'
                    >
                      {' '}
                      Hybrid Intelligence in Decision Support and a Use Case: Predictive Crisis
                      Management
                    </Link>
                  </h3>
                </header>
                {/* <p className='flex-grow text-lg text-gray-400'>
                  How to address AI challenges in decision technologies
                </p> */}
                <footer className='flex items-center mt-4'>
                  <LazyLoadImage
                    className='flex-shrink-0 mr-4 rounded-full'
                    src={require('../images/team/hasan_suzen.png')}
                    width='40'
                    height='40'
                    alt='Author 01'
                  />

                  <div className='flex items-center font-medium text-gray-200 transition duration-150 ease-in-out hover:text-gray-100'>
                    <p>Hasan Suzen</p>
                    <span className='text-gray-700'> - </span>
                    <span className='text-gray-500'>June 8, 2021</span>
                  </div>
                </footer>
              </article>
              <article className='flex flex-col h-full' data-aos='fade-up'>
                <header>
                  <div className='block mb-6' to={`/blog/hybrid-intelligence-startup`}>
                    <figure
                      className='relative h-0 overflow-hidden rounded-sm cursor-pointer pb-9/16'
                      onClick={(e) => {
                        e.preventDefault();
                        setVideoModalOpen(true);
                      }}
                    >
                      <LazyLoadImage
                        className='absolute inset-0 object-cover w-full h-full transition duration-700 ease-out transform hover:scale-105'
                        src={require('../images/Next Generation Internet interview with our CEO.jpg')}
                        width='352'
                        height='198'
                        alt='News 01'
                      />
                    </figure>
                  </div>

                  <h3
                    className='mb-2 cursor-pointer h4'
                    onClick={(e) => {
                      e.preventDefault();
                      setVideoModalOpen(true);
                    }}
                  >
                    Next Generation Internet (NGI) Explorers - Interview with our CEO
                  </h3>
                </header>

                <footer className='flex items-center mt-4'>
                  <LazyLoadImage
                    className='flex-shrink-0 mr-4 rounded-full'
                    src={require('../images/logo-post.png')}
                    width='40'
                    height='40'
                    alt='Author 02'
                  />

                  <div className='flex items-center font-medium text-gray-200 transition duration-150 ease-in-out hover:text-gray-100'>
                    <p>Hybrid Core</p>
                    <span className='text-gray-700'> - </span>
                    <span className='text-gray-500'>Feb 19, 2021</span>
                  </div>
                </footer>
              </article>
              <Modal
                id='modal'
                ariaLabel='modal-headline'
                show={videoModalOpen}
                handleClose={() => setVideoModalOpen(false)}
              >
                <div className='relative pb-9/16'>
                  <iframe
                    className='absolute w-full h-full'
                    src='https://www.youtube.com/embed/7Oa6SQyROUA'
                    frameBorder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    title='Video'
                  ></iframe>
                </div>
              </Modal>
              {/* 3rd article */}
              <article className='flex flex-col h-full' data-aos='fade-up' data-aos-delay='200'>
                <header>
                  <Link className='block mb-6' to={`/blog/smart-navigator-announcement`}>
                    <figure className='relative h-0 overflow-hidden rounded-sm pb-9/16'>
                      <LazyLoadImage
                        className='absolute inset-0 object-cover w-full h-full transition duration-700 ease-out transform hover:scale-105'
                        src={require('../images/announcement.jpeg')}
                        width='352'
                        height='198'
                        alt='News 02'
                      />
                    </figure>
                  </Link>

                  <h3 className='mb-2 h4'>
                    <Link
                      to={`/blog/smart-navigator-announcement`}
                      className='transition duration-150 ease-in-out hover:text-gray-100'
                    >
                      Smart Navigator Announcement
                    </Link>
                  </h3>
                </header>
                {/* <p className='flex-grow text-lg text-gray-400'>
                  We are happy to announce that our project, Smart Navigator which provides
                  next-generation decision technology, is awarded as one of top exploration by the
                  Next Generation Internet Explorers Team.
                </p> */}
                <footer className='flex items-center mt-4'>
                  <LazyLoadImage
                    className='flex-shrink-0 mr-4 rounded-full'
                    src={require('../images/logo-post.png')}
                    width='40'
                    height='40'
                    alt='Author 02'
                  />

                  <div className='flex items-center font-medium text-gray-200 transition duration-150 ease-in-out hover:text-gray-100'>
                    <p>Hybrid Core</p>
                    <span className='text-gray-700'> - </span>
                    <span className='text-gray-500'>Oct 13, 2020</span>
                  </div>
                </footer>
              </article>
              <article className='flex flex-col h-full' data-aos='fade-up' data-aos-delay='200'>
                <header>
                  <Link className='block mb-6' to={`/blog/hybrid-intelligence-startup`}>
                    <figure className='relative h-0 overflow-hidden rounded-sm pb-9/16'>
                      <LazyLoadImage
                        className='absolute inset-0 object-cover w-full h-full transition duration-700 ease-out transform hover:scale-105'
                        src={require('../images/startup-cooperation.jpg')}
                        width='352'
                        height='198'
                        alt='News 02'
                      />
                    </figure>
                  </Link>

                  <h3 className='mb-2 h4'>
                    <Link
                      to={`/blog/hybrid-intelligence-startup`}
                      className='transition duration-150 ease-in-out hover:text-gray-100'
                    >
                      Hybrid intelligence startup cooperation with governments and companies in the US and Europe?
                    </Link>
                  </h3>
                </header>

                <footer className='flex items-center mt-4'>
                  <LazyLoadImage
                    className='flex-shrink-0 mr-4 rounded-full'
                    src={require('../images/logo-post.png')}
                    width='40'
                    height='40'
                    alt='Author 02'
                  />

                  <div className='flex items-center font-medium text-gray-200 transition duration-150 ease-in-out hover:text-gray-100'>
                    <p>Hybrid Core</p>
                    <span className='text-gray-700'> - </span>
                    <span className='text-gray-500'>Oct 13, 2020</span>
                  </div>
                </footer>
              </article>
              {/* 4th article */}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
