import React from 'react';

import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import Tabs from '../partials/Tabs';
import News from '../partials/News';
import Clients from '../partials/Clients';
import Footer from '../partials/Footer';
import HeroFeatures from '../partials/HeroFeatures';
import CircleIllustration from '../partials/CircleIllustration';
import CoreFeatures from '../partials/CoreFeatures';
import BenefitsAndTechnology from '../partials/BenefitsAndTechnology';

function Home(props) {
  const logos = props.logosClient;
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page illustration */}
        <div className='relative h-0 max-w-6xl mx-auto pointer-events-none' aria-hidden='true'>
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <HeroFeatures />
        <CoreFeatures />
        <BenefitsAndTechnology />
        <Tabs />
        <News />

        <div className='relative'>
          <div
            className='absolute hidden pointer-events-none -left-72 -bottom-72 lg:block'
            aria-hidden='true'
            data-aos='fade-up'
          >
            <CircleIllustration
              className={'max-w-full'}
              width={'500'}
              height={'400'}
              fill={'url(#illustration-02)'}
            />
          </div>
        </div>
        <Clients logos={logos} />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
