import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroTeam from '../partials/HeroTeam';

import Team from '../partials/Team';

import Footer from '../partials/Footer';

function TeamPage() {
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <main className='flex-grow'>
        <div className='relative max-w-6xl mx-auto h-0 pointer-events-none' aria-hidden='true'>
          <PageIllustration />
        </div>
        <HeroTeam />
        <Team />
      </main>
      <Footer />
    </div>
  );
}

export default TeamPage;
