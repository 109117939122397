import React from 'react';
// import CircleIllustration from './CircleIllustration';
import TeamCard from './TeamCard';

const teamMembers = [
  {
    name: 'Hasan Suzen, Ph.D ',
    position: 'Founder & CEO',
    path: 'hasan_suzen.png',
  },
  {
    name: 'Erdem Kunduraci',
    position: 'Co-founder & CTO',
    path: 'Erdem Kunduraci Hybrid_Core.jpg',
  },
  {
    name: 'Erman Atak',
    position: 'Chief Operating Officer',
    path: 'ERMAN ATAK 0523.jpeg',
  },
  {
    name: 'Joseph Vural',
    position: 'HR Manager',
    path: 'Joseph Vural HR Manager.jpeg',
  },
  {
    name: 'Veysel Kocaman, Ph.D',
    position: 'AI Consultant',
    path: 'veysel_kocaman.png',
  },
  {
    name: 'Berfin Kayatas',
    position: 'UX Designer',
    path: 'MS BERFIN.jpeg',
  },
  {
    name: 'Nikola Grunchevski',
    position: 'VR/AR Software Engineer',
    path: 'Nikola Grunchevski 0523.jpeg',
  },
  {
    name: 'Serhat Toktamisoglu',
    position: 'Principal Software Engineer',
    path: 'Serhat Toktamisoglu 0523.jpeg',
  },
  {
    name: 'Ahmet Unaz',
    position: 'Full-Stack Software Engineer',
    path: 'Ahmet Unaz 0523.jpeg',
  },
  {
    name: 'Mehmet Ozturk',
    position: 'Senior Backend Software Engineer',
    path: 'Mehmet Ozturk 0523.jpeg',
  },
  {
    name: 'Cihan Aydiner, Ph.D',
    position: 'Red Teaming and Homeland Security Advisor',
    path: 'cihan_aydiner.png',
  },
  {
    name: 'Adam Milas',
    position: 'Data Scientist',
    path: 'M_Rasit_Saygili_Data_ Scientist.jpeg',
  },
  {
    name: 'Salih Tutun, Ph.D',
    position: 'Senior AI Advisor',
    path: 'salih_tutun.png',
  },
  {
    name: 'Emre Bilgin',
    position: 'Senior Data Science Advisor',
    path: 'Emre_Bilgin_Senior_Data_Scientist.jpeg',
  },
  {
    name: 'Dean Barwick',
    position: 'Backend Software Engineer',
    path: 'Dean Barwick, Backend Software Engineer Hybrid Core.png',
  },
  {
    name: 'Saban Yuksel',
    position: 'Training Program Advisor',
    path: 'Saban Yuksel.jpeg',
  },
  {
    name: 'Furkan Akar',
    position: 'Computational Domain Analyst',
    path: 'Furkan Akar Hybrid_Core.jpeg',
  },
  {
    name: 'Muzaffer Gezer',
    position: 'Strategic Partnership Manager and Computational Domain Expert',
    path: 'Muzaffer Gezer Hybrid_Core.jpeg',
  },
];
const advisors = [
  {
    name: 'Prof. Huuges Bersini',
    position: 'AI Team Supervisor, Director at Computer and Decision Engineering,CoDe IRIDIA ULB',
    path: 'huuges_bersini.png',
  },
  {
    name: 'Prof. Wim Hardyns',
    position: 'Supervisor Criminology & Safety Sciences at the Ghent University',
    path: 'wim_hardyns.png',
  },
  {
    name: 'Prof. Catholijn M. Jonker',
    position: ' Professor at Delft University of Technology and Leiden University',
    path: 'catholijn-jonker.jpg',
  },
  {
    name: 'Nirwan Ansari',
    position:
      'Distinguished Professor of Electrical and Computer Engineering at New Jersey Institute of Technology',
    path: 'nirwavansari.jpg',
  },
  {
    name: 'Emmanuel R. Goffi, PhD',
    position:
      'Co-Director and co-Founder of the Global AI Ethics Institute | AI Ethicist | Philosophy and Ethics Officer | Consultant and Speaker in Ethics',
    path: 'Emmanuel R. Goffi.jpg',
  },
  {
    name: 'Kristof Lybaert',
    position: 'Financial Advisor',
    path: 'Kristof Lybaert.jpeg',
  },
];
function Team() {
  return (
    <section>
      <div className='border-gray-800 '>
        <div className='px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:px-8 lg:py-24'>
          <div className='space-y-8 sm:space-y-12'>
            <div className='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'></div>
            <ul className='grid grid-cols-2 mx-auto gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-2 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3'>
              {teamMembers.map((member) => {
                return <TeamCard key={member.name} member={member} />;
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* Advisors */}
      <div className='border-t border-gray-800'>
        <div className='px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:px-8 lg:py-24'>
          <div className='space-y-8 sm:space-y-12'>
            <div className='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'>
              <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl text-gradient bg-gradient-to-r from-green-400 to-blue-500'>
                ADVISORY BOARD
              </h2>
            </div>
            <ul className='grid grid-cols-2 mx-auto gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-2 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3'>
              {advisors.map((advisor) => {
                return <TeamCard key={advisor.name} member={advisor} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
