import React from 'react';

function KT4DComponent() {
  return (
    <article>
      <div className='text-lg text-gray-400'>
        <p className='mb-8'>
          Artificial Intelligence and Big Data are profoundly intertwined with every aspect of our
          lives, affecting our societies, culture and notably, the notion of democratic governance.
          Knowledge Technologies for Democracy (KT4D) is a trans-disciplinary project aiming to map
          the risks posed by these technologies as well as their potential to enhance our
          democracies.
        </p>
        <p className='mb-8'>
          KT4D strives to establish clear definitions and guidelines for where and how democracy is
          placed under threat by AI and big data and provide different solutions, options, and
          options to the public audience, policy-makers, researchers and software developers in
          order to achieve a more inclusive civic participation.
        </p>
        <p className='mb-4'>
          The results of KT4D will be tested across three user needs scenarios:
        </p>
        <ul className='mb-8 list-decimal px-4 sm:px-6'>
          <li>capacity-building for citizens and citizen-facing Civil Society Organisations,</li>
          <li> devising regulatory tools and services for Policy and CSOs,</li>
          <li>
            enhancing awareness of how to develop ethically democratic principles in software
            development.{' '}
          </li>
        </ul>
        <p className='mb-8'>
          KT4D envisions providing robust, disruptive, ambitious, as well as feasible, and tangible
          methods that can positively impact how democracy and civic engagement may be not just
          maintained and defended against fast-changing knowledge technologies. It also aims to
          enable different stakeholders to take advantage of the various benefits these technologies
          may provide regarding community empowerment, social integration, and building trust in
          institutions and technological tools.
        </p>
        <p className='mb-8'>
          <b>Project Dates:</b>&nbsp; February 2023-2026
        </p>
        <p className='mb-8'>
          <b>Funder:</b>&nbsp; &nbsp; European Commission (Horizon Europe)
        </p>
      </div>
    </article>
  );
}

export default KT4DComponent;
