import React from 'react';
import Img from 'react-cool-img';
import Wave from './Wave';

function HeroSmartPredictivePolicing() {
  return (
    <section className='relative'>
      {/* Background image */}
      <div className='absolute inset-0'>
        <Img
          className='object-cover w-full h-full'
          src={require('../images/logo-1.webp')}
          width='1440'
          height='394'
          alt='About'
        />
        <div className='absolute inset-0 bg-gray-900 opacity-25' aria-hidden='true'></div>
      </div>

      {/* Hero content */}
      <div className='relative max-w-6xl px-4 mx-auto sm:px-6'>
        <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
          <div className='max-w-3xl mx-auto text-center'>
            <h1
              className='mb-4 h1 text-gradient bg-gradient-to-r from-green-400 to-blue-500'
              data-aos='fade-up'
            >
              SMART PREDICTIVE POLICING
            </h1>
          </div>
        </div>
      </div>
      <Wave />
    </section>
  );
}

export default HeroSmartPredictivePolicing;
