import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function BlogSingle() {
  return (
    <>
      <section className='relative'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6'>
          <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
            <div className='max-w-3xl mx-auto'>
              <article>
                <header className='mb-8'>
                  {/* Title and excerpt */}
                  <div className='text-center md:text-left'>
                    <h1 className='h1 mb-4' data-aos='fade-up'>
                      Hybrid intelligence startup cooperation with governments and companies in the
                      US and Europe?
                    </h1>
                  </div>
                  {/* Article meta */}
                  <div className='md:flex md:items-center md:justify-between mt-3'>
                    {/* Author meta */}
                    <div
                      className='flex items-center justify-center'
                      data-aos='fade-up'
                      data-aos-delay='400'
                    >
                      <Link to='#'>
                        <img
                          className='rounded-full flex-shrink-0 mr-4'
                          src={require('../images/team/hasan_suzen.png')}
                          width='40'
                          height='40'
                          alt='Author 04'
                        />
                      </Link>
                      <div>
                        <Link
                          to='#'
                          className='font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out'
                        >
                          Hasan SUZEN
                        </Link>
                        <span className='text-gray-700'> - </span>
                        <span className='text-gray-500'>Sept,13 2020</span>
                      </div>
                    </div>
                    {/* Article tags */}
                    <div
                      className='flex justify-center mt-4 md:mt-0'
                      data-aos='fade-up'
                      data-aos-delay='600'
                    ></div>
                  </div>
                </header>

                {/* Article image */}
                <figure
                  className='mb-8 lg:-ml-32 lg:-mr-32'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <img
                    className='w-full'
                    src={require('../images/startup-cooperation.jpg')}
                    width='1024'
                    height='576'
                    alt='News single'
                  />
                </figure>

                {/* Article content */}
                <div className='text-lg text-gray-400'>
                  <h3 className='h3 mb-4 text-gray-200'>
                    Is it possible to deal with chaos thanks to current technologies?
                  </h3>
                  <p className='mb-8'>
                    Even though the American scientist was one of the fathers of meteorological
                    predictability, he did not have enough technical facilities to be not so
                    rigorous concerning chaos. Artificial Intelligence and Machine Learning cannot
                    replace human minds, but they have made it possible processing of the huge data
                    amounts. Hasan Suzen is among the pioneers who have understood how to use AI for
                    chaos analysing and has founded a startup named Hybrid Core. To know more about
                    the astonishing way towards cooperation with international organisations,
                    governments, and companies in Europe and the States we decided to do an
                    interview with him.
                  </p>
                  <h3 className='h3 mb-4 text-gray-200'>
                    What is Hybrid Core and how it makes life better?
                  </h3>
                  <p className='mb-8'>
                    “ We are a hybrid intelligence company,” began the CEO of Hybrid Core.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    This is a new phenomenon in the field, and our team is passionate to develop it.
                    Hybrid CORE develops next-generation decision technology. With high-tech privacy
                    and precision, we provide Explainable Hybrid Intelligence Solutions to public
                    and private sectors in actionable business intelligence, bespoke intelligent
                    threat & risk analysis, targeted marketing & sales, and smart safety & security.
                  </blockquote>
                  <p className='mb-8'>
                    It is always interesting to know how the startup concept has appeared and what
                    has been its cornerstones. “ The underlying idea is that there is no chaos in
                    the universe. Each decision, action, selection or choice has its own
                    mathematical models. Interconnectivity, interactions and relations in complex
                    ecosystems can be configured with deep math and predicted through AI algorithms
                    but with human augmentation and amplification.”
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    The underlying idea is that there is no chaos in the universe. Each decision,
                    action, selection or choice has its own mathematical models. Hasan Suzen
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>
                    Preconditions and Drivers of Hybrid Core Existence
                  </h3>
                  <p className='mb-8'>
                    Positioning itself as the improved global AI and ML decisions provider seems to
                    be unreal for the startup, and Hasan Suzen has explained how they cope with the
                    competition in this sphere.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Actually, Hybrid Core is the brainchild of Beyond the Horizon International
                    Strategic Studies Group, which is an independent, non-profit think and do tank.
                    We also have roots in Horizon Global Academy, which provides training and
                    consultancy services mainly in AI and Cyber Security domains. These two
                    organisations have already provided insight and vison to governments,
                    international organisations, practitioners, researchers, and industry
                    representatives as well. This means that Hybrid Core is well established on
                    these valuable backgrounds and an extensive network of networks.
                  </blockquote>
                  <p className='mb-8'>
                    To bring more understanding to the discussion, the interview also highlighted
                    their core drivers.
                  </p>

                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Our principal motivation is the rising demand for adoption of AI to improve
                    planning, threat & risk monitoring, early detection, and predictive assessment
                    both in public and private sectors. Additionally, we aimed at improving the
                    operational efficiency of autonomous systems and pre-emptive applications.
                  </blockquote>

                  <h3 className='h3 mb-4 text-gray-200'>The products of the firm</h3>
                  <p className='mb-8'>
                    What relates to the products Hybrid Core offers, its CEO divided them into a few
                    categories.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    <p className='mb-8'>
                      Our product portfolio, Hybrid Intelligence-Based Decision Support Systems,
                      consists of three main products:
                    </p>

                    <ul className='grid gap-8 md:grid-cols-2 xl:gap-x-16 lg:gap-y-12 mb-8'>
                      <li>
                        <h4 className='text-xl font-medium mb-2 text-gray-200'>Smart Navigator:</h4>
                        <p className='text-lg text-gray-400'>
                          It transforms big data into actionable business intelligence; bespoke
                          intelligent risk & threat analysis.
                        </p>
                      </li>
                      <li>
                        <h4 className='text-xl font-medium mb-2 text-gray-200'>Smart Sonar:</h4>
                        <p className='text-lg text-gray-400'>
                          It transforms big data into targeted marketing & sales and provides,
                          optimises customer acquisition and maximises marketing campaign.
                        </p>
                      </li>
                      <li>
                        <h4 className='text-xl font-medium mb-2 text-gray-200'>
                          Smart Predictive Policing:
                        </h4>
                        <p className='text-lg text-gray-400'>
                          It transforms big data into safety & security in line with smart cities
                          approaches.
                        </p>
                      </li>
                    </ul>
                  </blockquote>

                  <div className='max-w-3xl mx-auto text-center mb-8'>
                    <p className='text-xl text-gray-400 mb-8'>
                      In addition to smart products, we deliver the following services:
                    </p>

                    {/* Check list */}
                    <ul className='flex flex-wrap justify-center text-lg text-gray-400 -mx-2 -my-1'>
                      <li className='flex items-center mx-3 my-1'>
                        <svg
                          className='w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z' />
                        </svg>
                        <span>Proprietary Databases</span>
                      </li>
                      <li className='flex items-center mx-3 my-1'>
                        <svg
                          className='w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z' />
                        </svg>
                        <span>
                          Strategic Foresights Analysis (Case-Based Analysis and interactive white
                          papers)
                        </span>
                      </li>
                      <li className='flex items-center mx-3 my-1'>
                        <svg
                          className='w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z' />
                        </svg>
                        <span>Smart Preemptive Decision-Making Support (Case Management)</span>
                      </li>
                    </ul>
                  </div>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    The list of services can say a little about the product, while the list of
                    clients or their industry specifics broadens the perception. “ Our targeted
                    customers are companies operating in energy security, defence, automotive,
                    construction, banking, health and food; governmental institutions, International
                    organisations such NATO and the EU; and Academia,” added Hasan Suzen.
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>What spheres are requiring AI?</h3>
                  <p className='mb-8'>
                    It may seem strange that the world’s most influential organisations are looking
                    for technological support from the side of startups, so we have asked how Hybrid
                    Core has found a gap in this kind of demand.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Developing fully autonomous AI-powered systems is a long journey, but there is a
                    rising demand for AI products in the market; at the same time, trustworthy and
                    precision are really big concerns. In essence, there is a gap in the market
                    regarding trusted AI-powered decision support systems in the public and private
                    sector as well. As recent studies show, European AI industries mainly focus on
                    data analytics, sales & marketing, health & medicine, process automation, and
                    image recognition.” “In essence, there is a gap in the market regarding trusted
                    AI-powered decision support systems in the public and private sector as well.
                    Hasan Suzen
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>
                    The human factor makes AI usage much more effective
                  </h3>
                  <p className='mb-8'>
                    Even though AI development has come to a standstill to some extent, Hasan Suzen
                    explained what has allowed them to use this technology in many spheres.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Although only data analytics can be used for decision support tool, there is
                    still a need for algorithmic decision systems but with human augmentation and
                    amplification for addressing the trustworthy and high accuracy concerns.
                    Furthermore, recent AI use cases highlight the need for human augmentation,
                    particularly human-machine teaming addressing complex problems. The reason is
                    simple: algorithmic decisions are not automatically equitable just by virtue of
                    being the products of complex processes, and the procedural consistency of
                    algorithms is not equivalent to objectivity.
                  </blockquote>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Additionally, although there exists a large volume of efforts at the EU and
                    governmental levels, there is still lack of applied intelligent systems
                    developed by companies to address societal challenges such as radicalisation,
                    extremism, terrorism, or migration & integration. Considering all these reasons,
                    we think that it is high time to start a business in next-generation decision
                    technology.
                  </blockquote>
                  <p className='mb-8'>concluded a CEO of Hybrid Core.</p>
                  <h3 className='h3 mb-4 text-gray-200'>
                    What developers should a startupper hire to deal with AI?
                  </h3>
                  <p className='mb-8'>
                    Another interesting question relates to the possibilities that a startup
                    possesses to applying AI in such a broad sense.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    In the software part, to accomplish complex tasks and collectively achieve
                    superior results, human intelligence and AI experts work symbiotically. The
                    concept of the project will be materialised by a cross-functional human-machine
                    team which consists of artificial intelligence and data scientists on the one
                    side; computational social scientists and neuroscientists, and subject matter
                    experts on the other.
                  </blockquote>
                  <p className='mb-8'>
                    To give more details, Hasan Suzen continued by describing the methodology they
                    use.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Also, we work with disruptive technologies and developed our own new
                    methodologies and approaches to an algorithmic decision. Namely, they are
                    multi-space actor-event (incident)-factor-indicator analysis model, a neural
                    network of neural networks, privacy-preserving federated algorithms, and
                    multiple futures scenarios simulations, which enable us to provide hybrid
                    intelligence solutions. We hope it could be a ‘game-changer or game broker’ in
                    the market.
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>
                    The future of hybrid intelligence technology
                  </h3>
                  <p className='mb-8'>
                    The issue of the future development of technologies is a tough one nowadays, but
                    the interviewee sees various perspectives of their work.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    We believe that our hybrid intelligence-based decision support systems will
                    revolutionise how we model, predict and control complex systems. We could
                    provide solutions with unique algorithms and hybrid toolbox, combining human and
                    machine expertise and using interdisciplinary approaches. The backbone of our
                    cognitive products is a deep understanding of patterns & interconnectivity in
                    complex ecosystems and mapping cognitive architecture beyond computational
                    creativity or the information dynamics of thinking. From this perspective, it
                    could be an advantage for us to develop reusable mathematical configurations and
                    algorithm models.The backbone of our cognitive products is a deep understanding
                    of patterns & interconnectivity in complex ecosystems and mapping cognitive
                    architecture beyond computational creativity or the information dynamics of
                    thinking. Hasan Suzen
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>Initial funding and how to cope with it</h3>
                  <p className='mb-8'>
                    Amid the most challenging elements of every a startup development is the budget,
                    especially on the first stages. Hasan Suzen opened the curtain how it was in the
                    Hybrid Core’s case.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    The initial source of capital investment and startup money are personal assets
                    and our own resources. We have a limited budget during the R&D process, but our
                    team put their heart and soul into what they are doing on a voluntary basis in
                    most of the time. They truly believe in their product or service, and are
                    prepared to work long hours to get all the tasks done that are required to grow
                    and sustain our business.
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>How does the team deliver quality?</h3>
                  <p className='mb-8'>
                    At that point, the discussion smoothly turned to the talking about the
                    programmers, engineers, project manager, and other staff that develops the
                    product.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    "The uniqueness of our team comes from its structure, composition and group
                    dynamics. The dedicated and highly sophisticated cross-functional human-machine
                    team is the most valuable asset which enables us to make an asymmetric effect in
                    the field. With a strong team and innovative methodologies, we target the big
                    gap in the market, and we hope to be a game-changer or at least game broker in
                    the global market. That is because we are unique in providing hybrid
                    intelligence-based decision support by addressing privacy and precision concerns
                    " concluded the Hybrid Core CEO.
                  </blockquote>
                  <p className='mb-8'>
                    One more unique aspect concerning any small firm or a startup is how to measure
                    success.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Our product development life cycle is divided into small components or
                    iterations, so our products can be easily and rapidly developed and tested.
                    Using iterative planning and getting feedback ensures that a delivered product
                    is tailored to the customer’s requirements. The status of the project is
                    measured and evaluated throughout the process to easily adapt to changing needs.
                    As we have complex software development processes, we use scrum framework which
                    defines specific roles, artefacts, activities, metrics for our projects. In
                    short, we use not only innovative methodologies for developing our products but
                    also, we use modern project management technics and tools.
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'>Clients’ list and plans that impress</h3>
                  <p className='mb-8'>
                    We asked the interviewee to share what were the biggest customers they had tried
                    to reach and how Hybrid Core saw the development with them.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    I can declare that the first biggest customer will be the EU itself and/or some
                    European countries and companies in the coming months based on ongoing
                    processes. Also, we want to see ‘hockey stick’ adoption rates which imply
                    consistent or increasing growth rates on a percentage basis. To this end, the
                    most exciting users to build up traction are some EU governments, the EU itself,
                    and energy companies at the moment.
                  </blockquote>
                  <p className='mb-8'>
                    Despite the fact that Hybrid Core is making an effective forecast in different
                    spheres, it was interesting to know how Hasan Suzen saw their further
                    development.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Next year, we aim to get users and as well as investors from North America in
                    addition to Europe and the Middle Eastern countries and companies.
                    Incrementally, we hope to access to global markets and get 1M and 4M euro
                    revenues, respectively. In general, we want to generate a stable customer base
                    and recurring revenues.
                  </blockquote>
                  <h3 className='h3 mb-4 text-gray-200'> Advice for young entrepreneurs</h3>
                  <p className='mb-8'>
                    Our traditional request to all the startuppers is to give some useful advice for
                    other entrepreneurs.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    To me, they have to be a “Type D” person. Stratuppers have to be someone who has
                    a Desire for success coupled with Drive, Discipline, and Determination. A
                    business idea is a starting point, but they need more than an idea.
                  </blockquote>
                  <p className='mb-8'>Hasan Suzen continued the though he wanted to convey.</p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    First, business knowledge before they start a business is critical. They have to
                    be knowledgeable about many different aspects of business and have a variety of
                    skills. Secondly, finding adequate business startup money is also critical
                    because there’s no guarantee that your business is going to make money right
                    away, and often initial income is too low to live on. In essence, the lack of
                    sufficient funds is a top reason most small businesses fail, but they need to
                    believe that the obstacles they face are temporary barriers to work around, not
                    roadblocks.
                  </blockquote>
                  <p className='mb-8'>
                    The last element may seem of secondary importance, but Hybrid Core’s CEO made
                    the biggest emphasis on it.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Finally, they [startuppers] must think of business support. To do this, they
                    need to look first to the “home front”. Their family should be their first
                    believers when it comes to their success. Support can come from other sources
                    such as other prosperous business people who can help them avoid dangers and
                    provide insight into what works and what doesn’t. One more crucial aspect is
                    business connections, as the network is everything.
                  </blockquote>

                  <h3 className='h3 mb-4 text-gray-200'>
                    {' '}
                    How to deal with chaos, and why is it crucial?
                  </h3>
                  <p className='mb-8'>
                    The concluding part of the interview with Hasan Suzen was a bit philosophical,
                    where both optimism and realism clashed for the truth.
                  </p>
                  <blockquote className='italic pl-4 border-l-2 border-gray-200 mb-8'>
                    Among our biggest threats is the product’s complexity. From possible investors
                    or customers’ perspective, our sophisticated products may seem too complex to
                    explain how our technology provides full privacy and precision in decision
                    making support. That is why my favourite book is ‘A Brief History of Time’ by
                    Stephen Hawking. I understand how incredible and beautiful our organised chaos
                    of existence is and what there is to discover in our lifetime.
                  </blockquote>
                  <p className='mb-8'>
                    At the beginning of the interview, it was a bit weird to see that a startup
                    could suggest complex AI and ML solutions for international organisations.
                    However, Hasan Suzen showed his understanding of the preconditions, pitfalls,
                    and possible outcomes of the vast implementation of new technologies on the
                    highest level. One more time it has to inspire young entrepreneurs that the
                    right approach to business can transform a startup into an influential firm even
                    on the global stage.
                  </p>
                </div>

                {/* Article footer */}
                <footer>
                  <div className='md:flex md:items-center md:justify-between text-center md:text-left'>
                    <div className='text-lg text-gray-400 italic'>
                      Originally published at{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://www.orangesputnik.eu'
                        alt='orangesputnik website'
                        className='text-purple-500 '
                      >
                        https://www.orangesputnik.eu
                      </a>
                    </div>
                  </div>
                </footer>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogSingle;
